<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="col-sm-12 login-right-contents">
          <div class="login-inner">
            <h1 class="text-nowrap">JET 試験管理 アカウント登録</h1>
            <p class="text-explanation">
              教室コードとパスワードを入力し「次へ」をクリックしてください。
            </p>
            <form v-form>
              <div class="form-group">
                <label for="siteCode">教室コード</label>
                <input-text v-model="form.siteCode" :error="$error('siteCode')"
                  inputClass="mb-1"
                  autocomplete="off"
                  :default-focus="true" />
                <label for="passowrd">パスワード</label>
                <input-password v-model="form.password" :error="$error('password')"
                  autocomplete="off"
                  inputClass="mb-2" />
              </div>
              <div class="text-center buttons">
                <span @click="siteAuth" class="btn btn-primary font-weight-bold">次へ</span>
              </div>
            </form>
          </div>
        </div>
        <div class="col-sm-12">
          <footer class="footer">
            <p>© 2022 JET Committee.</p>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Service} from './_resource';

export default {
  data () {
    return {
      form: {
        organizationId: 1,
        siteCode: null,
        password: null,
      },
      error: null,
    }
  },
  mounted() {
    document.body.classList.add('login')
  },
  methods: {
    siteAuth () {
      Service.siteAuth(this.form)
          .then(data => {
            this.$store.commit('setRegistrationToken', data.registrationToken);
            this.$move('email');
          })
          .catch(error => {
            this.error = error;
          });
    }
  }
};
</script>
