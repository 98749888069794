<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="col-sm-12 login-right-contents">
          <div class="login-inner">
            <h1 class="text-nowrap">JET 試験管理 アカウント登録</h1>
            <p class="text-explanation">
              入力されたメールアドレス宛てに認証コードを送信しました。<br>
              メールに記載されている6桁の認証コードを入力してください。<br>
            </p>
            <form v-form>
              <div class="form-group">
                <label for="code">認証コード</label>
                <div :class="{ wrong: codeError }">
                  <input-text v-model="code" inputClass="mb-1" @keyup="checkCode"
                              :default-focus="true" />
                </div>
              </div>
            </form>
            <p class="text-explanation">
              しばらく待ってもメールが届かない場合は <a @click="$move('email')">前の画面に戻って</a><br>
              メールアドレスを確認し、再度認証コードを送信してください。
            </p>
          </div>
        </div>
        <div class="col-sm-12">
          <footer class="footer">
            <p>© 2022 JET Committee.</p>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Service} from './_resource';

export default {
  data () {
    return {
      code: '',
      codeError: false,
    }
  },
  created () {
    Service.getSignUpInfo()
        .then(data => {
          if (data == null) {
            this.$move('start');
            return;
          }
          if (data.email == null) {
            this.$move('email');
            return;
          }
          if (data.confirmed) {
            this.$move('register');
            return;
          }
        })
        .catch(() => {
          this.$move('start');
        })
  },
  mounted() {
    document.body.classList.add('login')
  },
  methods: {
    checkCode () {
      if (this.code.trim().length == 6) {
        Service.confirmCode(this.code)
            .then(() => {
              this.$move('register');
            })
            .catch(() => {
              this.code = '';
              this.codeError = true;
              setTimeout(() => { this.codeError = false; }, 360);
            });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.login {
  .login-right-contents {
    .login-inner {
      form {
        margin: 3rem 15rem;
      }
    }
  }
}
.wrong {
  animation: shake 0.12s linear;
  animation-iteration-count: 3;
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }

  40% {
    transform: translateX(-3%);
  }

  80% {
    transform: translateX(3%);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
