<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="col-sm-12 login-right-contents">
          <div class="login-inner">
            <h1 class="text-nowrap">JET 試験管理 アカウント登録</h1>
            <p class="text-explanation">
              ログインに使用するメールアドレスを入力してください。<br>
              入力されたメールアドレス宛てに認証コードを送信します。
            </p>
            <form v-form>
              <div class="form-group">
                <label for="email">メールアドレス</label>
                <input-text v-model="email" :error="$error('email')"
                  inputClass="mb-1"
                  :default-focus="true" />
              </div>
              <div class="text-center buttons">
                <span @click="sendConfirmationCode()" class="btn btn-primary font-weight-bold">認証コードを送信する</span>
              </div>
            </form>
          </div>
        </div>
        <div class="col-sm-12">
          <footer class="footer">
            <p>© 2022 JET Committee.</p>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Service} from './_resource.js';

export default {
  data () {
    return {
      email: '',
      error: null,
    }
  },
  created () {
    Service.getSignUpInfo()
        .then(data => {
          if (data == null || data.used) this.$move('start');
        })
        .catch(() => {
          this.$move('start');
        })
  },
  mounted () {
    document.body.classList.add('login');
  },
  methods: {
    sendConfirmationCode () {
      Service.sendConfirmationCode(this.email)
          .then(() => {
            this.$move('confirm');
          })
          .catch(error => {
            this.error = error;
          });
    }
  }
};
</script>

<style scoped lang="scss">
.login {
  .login-inner {
    form {
      margin: 3rem 0;
    }

    .buttons {
      margin-top: 3rem;
    }
  }
}
</style>
