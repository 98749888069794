<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="col-sm-12 login-right-contents">
          <div class="login-inner">
            <h1 class="text-nowrap">JET 試験管理 アカウント登録</h1>
            <p class="text-explanation">
              アカウント登録が完了しました。<br>
            </p>
            <form v-form>
              <div class="form-group mb-2">
                <label for="siteName">教室名</label>
                <div class="form-control-static text-left">{{siteName}}</div>
                <label for="email">メールアドレス</label>
                <div class="form-control-static text-left">{{email}}</div>
                <label for="name">お名前</label>
                <div class="form-control-static text-left">{{name}}</div>
              </div>
              <div class="text-center"><span @click="$move('../login')" class="btn btn-primary font-weight-bold">ログイン画面へ</span></div>
            </form>
          </div>
        </div>
        <div class="col-sm-12">
          <footer class="footer">
            <p>© 2022 JET Committee.</p>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Service} from "@/app/signup/_resource";

export default {
  data () {
    return {
      siteName: '',
      name: '',
      email: '',
      error: null,
    }
  },
  created () {
    Service.getSignUpInfo()
        .then(data => {
          if (data == null) {
            this.$move('start');
            return;
          }
          if (data.email == null) {
            this.$move('email');
            return;
          }
          if (!data.confirmed) {
            this.$move('confirm');
            return;
          }
          if (!data.used) {
            this.$move('register');
            return;
          }
          this.siteName = data.siteName;
          this.name = data.userName;
          this.email = data.email;
          this.$store.commit('setRegistrationToken', null);
        })
        .catch(() => {
          this.$move('start');
        })
  },
  mounted() {
    document.body.classList.add('login')
  }
};
</script>

<style lang="scss" scoped>
.login-inner p {
  font-size: 1.5rem;
}
</style>
